<template>
  <div>
    <div class="admin-detail">
      <div class="admin-navbar justify-between">
        <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
        <div class="detail-buttons">
          <button v-if="editable" class="detail-button"
                  @click="$router.push(`/admin/quotas/${$route.params.id}/edit?type=${$route.query.type}`)">
            {{ $lang.app.edit }}</button>
        </div>
      </div>
      <div v-if="quota" class="admin-info">
        <h4 class="info-title">{{ $lang.app.quotas }}</h4>
        <div class="info-fields">
          <div class="info-field">
            <p class="field-label">{{ $lang.app.organization }}:</p>
            <p class="field-value">{{ $lang.$translate({kg: quota.organizationNameKG, ru: quota.organizationNameRU}) }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.quota }}:</p>
            <p class="field-value">{{ quota.total }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="quota "
         class="admin-list">
      <h4 class="list-title">{{ $lang.app.groups }}</h4>
      <div class="admin-navbar align-right">
        <router-link v-if="groupCreatable" :to="`/admin/groups/create?organizationId=${quota.organizationId}`" class="action-button">{{ $lang.app.create }}</router-link>
      </div>
      <div v-if="groups.length" class="admin-table">
        <table>
          <thead>
          <tr>
            <th class="key">{{ $lang.app.education_language }}</th>
            <th>{{ $lang.app.position_quantity }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="group in groups" :key="group.id">
            <td class="key">{{ group.language.name }}</td>
            <td>{{ group.quota }}</td>
            <td>
              <div class="action-buttons">
<!--                <router-link :to="`/admin/groups/${group.id}`"-->
<!--                             class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>-->
                <router-link v-if="groupEditable" :to="`/admin/groups/${group.id}/edit?organizationId=${quota.organizationId}`"
                             class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
                <button v-if="groupDeletable" class="action-button" :title="$lang.app.delete" @click="deleteGroupRequest(group.id)"><i class="bi-x-square"></i></button>
              </div>
            </td>
          </tr>
          <tr>
            <td class="key" style="background-color: white">Итого</td>
            <td :class="{'green-background': isGreen(), 'red-background': !isGreen()}" style="text-align: center;">
              <span>{{ groups.map(item => Number(item.quota)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) }}</span>
              <span class="separator">/</span>
              <span class="field-value">{{ quota.total }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <DeleteModal v-if="deleteGroupId"
                   @close="deleteGroupId = null"
                   @delete="deleteGroupResource"
      />
    </div>
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  data() {
    return {
      quota: null,
      groups: [],
      deleteGroupId: null,
    }
  },
  computed: {
    editable() {
      return this.$store.getters.isAdmin || (this.$store.getters.isGorono /* && this.$route.query.type === 'PRESCHOOL' */)
    },
    groupCreatable() {
      return this.$store.getters.isAdmin || (this.$store.getters.isGorono /* && this.$route.query.type === 'PRESCHOOL' */)
    },
    groupEditable() {
      return this.$store.getters.isAdmin || (this.$store.getters.isGorono /* && this.$route.query.type === 'PRESCHOOL' */)
    },
    groupDeletable() {
      return this.$store.getters.isAdmin || (this.$store.getters.isGorono /* && this.$route.query.type === 'PRESCHOOL' */)
    }
  },
  watch: {
    '$route'(value, oldValue) {
      if(value.path === oldValue.path) {
        this.fetchGroupResources()
      }
    }
  },
  methods: {
    fetchQuota() {
      this.$axios.get(`/quota/${this.$route.params.id}`).then(({data}) => {
        this.quota = data
        this.fetchGroupResources()
      })
    },
    fetchGroupResources() {
      this.$axios.get(`/organization-group/organization/${this.quota.organizationId}`)
          .then(({data}) => {
            this.groups = data
          })
    },
    deleteGroupRequest(id) {
      this.deleteGroupId = id
    },
    deleteGroupResource() {
      this.$axios.delete(`/organization-group/${this.deleteGroupId}`).then(() => {
        this.fetchGroupResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      }).finally(() => {
        this.deleteGroupId = null
      })
    },
    isGreen() {
      return this.groups.map(item => Number(item.quota)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) === this.quota.total;
    }
  },
  mounted() {
    this.fetchQuota()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/table.css';
</style>